import timeZoneTransformer from '@/services/transformers/timeZoneTransformer.js'

const baseURL = 'time-zones'

export default {
  /**
   * Get supported timezones.
   *
   * @param params
   * @returns Promise
   */
  get (params = {}) {
    return axios.get(`${baseURL}/`, {
      params
    }).then(response => response.data.map(timeZoneTransformer))
  }
}
